import React, { useState } from 'react'
import { useLocalStorage } from 'react-use'

/* Icons */
import { FaceFrownIcon, FaceSmileIcon } from '@heroicons/react/24/outline'

/* Types */
import { Session, User } from '@supabase/supabase-js'

import dynamic from 'next/dynamic.js'
import { useRouter } from 'next/router.js'

import { useGetTranslations } from '@/i18n/index.tsx'

/* Context */
import { useApplicationContext } from '@/context/ApplicationContext'
import { useApplicationStore } from '@/context/ApplicationStore.tsx'
import { useAuthContext } from '@/context/AuthContext'

/* Constants */
import { NOTIFICATION_TYPE, STATUS_CONSTANTS } from '@/constants/constants.ts'

/* Enums */
import { ENUM_ACTION_TYPES, ENUM_HORECA_VENUE_TYPES, ENUM_MODES } from '@/enums/Enums.ts'

/* Utils */
import { getLocalizedTableData } from '@/utils/functionUtils'
import { vanillaTRPC as trpc } from '@/utils/trpc.ts'

import useFetchOnFocus from '@/hooks/useFetchOnFocus'
import useQueryDateRange from '@/hooks/useQueryDateRange'

/* Hooks */
import useRealtimeCustomerOrderSubscription from '@/hooks/useRealtimeCustomerOrderSubscription'
import { useVenuePrefix } from '@/hooks/useVenuePrefix.ts'

/* Components */
import LoadingSpinner from '@/components/shared/LoadingSpinner/LoadingSpinner.tsx'
import ModalPortal from '@/components/shared/ModalPortal/ModalPortal.tsx'
import PopupComponent from '@/components/shared/PopupComponent/PopupComponent'

import CustomerLayout from '@/layouts/CustomerLayout.tsx'

import {
    CategoryItemT,
    ExtendedFloorLocalizationT,
    ExtendedSectionLocalizationT,
    ExtendedTableT,
    ProfileT,
    TableT,
} from '@/types/globalTypes'

const DynamicMenuComponent = dynamic(
    () => import('@/components/shared/MenuComponent/MenuComponent.tsx'),
    {
        loading: () => <LoadingSpinner />,
        ssr: false,
    }
)
const DynamicPinCodeComponent = dynamic(
    () => import('@/components/TablePageComponent/PinCodeComponent/PinCodeComponent'),
    {
        ssr: false,
    }
)

const DynamicTablePageComponent = dynamic(
    () => import('@/components/TablePageComponent/TablePageComponent.tsx'),
    {
        ssr: false,
    }
)

const DynamicQRCode = dynamic(() => import('react-qr-code'), {
    loading: () => <LoadingSpinner />,
    ssr: false,
})

const DynamicMenuItemAddOnSelection = dynamic(
    () => import('@/components/shared/MenuComponent/MenuItemAddOnSelection.tsx'),
    {
        loading: () => <LoadingSpinner className="m-auto my-20" />,
        ssr: false,
    }
)

const TablePage = () => {
    const { TOAST_NOTIFICATIONS_TEXT, TABLE_PAGE_COMPONENT_TEXT } = useGetTranslations()

    const { supabaseClient, setSession, session, setProfile, setUser } = useAuthContext()

    const { setToastNotification, applicationState } = useApplicationContext()
    const router = useRouter()

    const { venueType } = useVenuePrefix()
    const [userSession, setUserSession] = useLocalStorage<Session>('userSession', null)

    const {
        state,
        dispatch,
        setLoading,
        handleCloseMenuItemsPopup,
        setSelectedMenuItems,
        setSelectedOrderSingleItem,
    } = useApplicationStore((state) => state.tablePage)

    const { queryStartDate, queryEndDate } = useQueryDateRange(null, false)

    const venueId = applicationState.venue.id
    const selectedLanguage = applicationState.selectedLanguage

    const { slug, is_static } = router.query

    const slugType = slug[0]
    const pageType = venueType === ENUM_HORECA_VENUE_TYPES.HOTEL ? 'rooms' : 'tables'

    const queryId = slug[1]

    const guest_id = session?.user?.app_metadata.guest_id || queryId

    const { reservation, tableData, orders, loading, selectedItemMode, selectedOrderSingleItem } =
        state

    const { setMenuAddonsPopup, state: modalState } = useApplicationStore(
        (state) => state.modalState
    )

    const { menuAddonsPopup } = modalState

    const [validPinCode, setValidPinCode] = useState(null)
    const [openPinCodePopup, setOpenPinCodePopup] = useState(false)

    const [reservationNotFound, setReservationNotFound] = useState(false)
    const [initializePageLoad, setInitializePageLoad] = useState(true)
    const [loadingQueryData, setLoadingQueryData] = useState(true)

    const isWithinRange =
        reservation?.date_of_reservation >= queryStartDate &&
        reservation?.date_of_reservation <= queryEndDate

    const queryData = async () => {
        if (!queryId || typeof window === 'undefined' || slugType !== pageType) {
            return
        }
        setLoading(true)

        try {
            const basePromises = {
                reservations: trpc.reservations.getData.query({
                    queryId,
                    venueId,
                    queryStartDate,
                    queryEndDate,
                    is_static: is_static === 'true',
                }),
                floors: trpc.layout.getFloorsLayout.query(venueId),
                sections: trpc.layout.getSectionsLayout.query({
                    venue_id: venueId,
                    selectedLanguage,
                }),
            }

            const results = await (session
                ? Promise.all([
                      basePromises.reservations,
                      basePromises.floors,
                      basePromises.sections,
                      trpc.orders.getCustomerOrdersByGuestId.query(guest_id),
                  ])
                : Promise.all([
                      basePromises.reservations,
                      basePromises.floors,
                      basePromises.sections,
                  ]))

            const [fetchedData, floors, sections, orders = []] = results
            const { reservationData, tableData } = fetchedData

            if (reservationData.length === 0) {
                setReservationNotFound(true)
                setInitializePageLoad(false)
                setLoadingQueryData(false)
                setLoading(false)
                return
            }

            let localizedTable = getLocalizedTableData(
                tableData[0] as TableT,
                sections as ExtendedSectionLocalizationT[],
                floors as ExtendedFloorLocalizationT[],
                applicationState.selectedLanguage
            )

            dispatch({
                type: ENUM_ACTION_TYPES.INIT_DATA,
                payload: {
                    reservation: reservationData[0],
                    tableData: localizedTable,
                    orders,
                    floors,
                    sections,
                },
            })
            setLoading(false)
            setLoadingQueryData(false)
        } catch (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: error.message || TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
            })
            setLoading(false)
            setLoadingQueryData(false)
        }
    }

    const checkSession = async () => {
        if (!queryId || typeof window === 'undefined' || slugType !== pageType || !reservation.id) {
            return
        }
        try {
            const sessionData = userSession
            let currentDate = new Date()
            const serverSession = await trpc.auth.getServerSession.query()
            let sessionReservationId = (serverSession.app_metadata as { reservation_id?: string })
                .reservation_id

            if (!sessionData || serverSession === null) {
                const { reservationData } = await trpc.reservations.getData.query({
                    queryId,
                    venueId,
                    queryStartDate,
                    queryEndDate,
                    is_static: is_static === 'true',
                })

                dispatch({
                    type: ENUM_ACTION_TYPES.INIT_DATA,
                    payload: {
                        reservation: reservationData[0],
                        tableData: tableData,
                        orders: orders,
                        floors: [],
                        sections: [],
                    },
                })

                setInitializePageLoad(false)
                setLoading(false)
                setOpenPinCodePopup(true)

                return
            }

            if (
                (sessionReservationId !== sessionData.user.app_metadata.reservation_id &&
                    loading === false) ||
                sessionReservationId !== reservation.id
            ) {
                setInitializePageLoad(false)
                setSession(null)
                setOpenPinCodePopup(true)
                return
            }

            /*
            If the token has expired we reset the token to the public one to allow for querying and creating a new one
        */
            if (currentDate >= new Date(sessionData.expires_at * 1000)) {
                supabaseClient.functions.setAuth(process.env.NEXT_PUBLIC_SUPABASE_KEY)
                supabaseClient.realtime.setAuth(process.env.NEXT_PUBLIC_SUPABASE_KEY)
                ;(supabaseClient as any).rest.headers.Authorization =
                    `Bearer ${process.env.NEXT_PUBLIC_SUPABASE_KEY}`

                setLoading(false)
                setInitializePageLoad(false)
                setSession(null)
                setOpenPinCodePopup(true)

                return
            }

            const { matches } = await trpc.pinCodes.verify.query({
                reservation_id: reservation.id,
                pin_code: sessionData?.user?.app_metadata.pin_code,
            })

            if (matches === false) {
                setLoading(false)
                setInitializePageLoad(false)
                setSession(null)
                setOpenPinCodePopup(true)
                return
            }

            supabaseClient.functions.setAuth(sessionData.access_token)
            supabaseClient.realtime.setAuth(sessionData.access_token)
            ;(supabaseClient as any).rest.headers.Authorization =
                `Bearer ${sessionData.access_token}`

            setValidPinCode(true)
            setSession(sessionData)
            setUser(sessionData.user)
            setProfile({
                id: sessionData.user.id,
                full_name: 'Guest',
                roles: sessionData.user.app_metadata.roles,
                avatar_url: '',
                main_role: 'CUSTOMER',
                reservation_id: sessionData.user.app_metadata.reservation_id,
                updated_at: null,
                username: null,
                venue_id: sessionData.user.app_metadata.venue_id,
                email: sessionData.user.email,
            })
            setOpenPinCodePopup(false)
            setInitializePageLoad(false)
        } catch (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: error.message || TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
            })
        }
    }

    const handleVerifyReservationPinCode = async (pinCode: string) => {
        try {
            const { matches } = await trpc.pinCodes.verify.query({
                reservation_id: reservation.id,
                pin_code: pinCode,
            })

            if (matches === false) {
                setValidPinCode(false)
                return
            } else {
                setValidPinCode(true)
                setLoading(true)
            }
            const { session, profile } = await trpc.auth.generateUserToken.query({
                pinCode,
                reservationId: reservation.id,
            })

            setUserSession(session as Session)
            supabaseClient.functions.setAuth(session.access_token)
            supabaseClient.realtime.setAuth(session.access_token)
            ;(supabaseClient as any).rest.headers.Authorization = `Bearer ${session.access_token}`

            setSession(session as Session)
            setUser(session.user as User)
            setProfile(profile as ProfileT)
            setValidPinCode(true)
            setLoading(false)
            setInitializePageLoad(false)
            setOpenPinCodePopup(false)
        } catch (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
            })
            setValidPinCode(false)
        }
    }

    /* Custom hook that refetches upon  window focus or visibility change*/
    useFetchOnFocus(() => {
        checkSession()
    }, [session, setSession, setUserSession, reservation.id])
    useFetchOnFocus(() => {
        queryData()
    }, [session, selectedLanguage, reservation.is_static, reservation.id])

    /* We call the Supabase realtime as a hook to listen for real time events */
    useRealtimeCustomerOrderSubscription({
        loading,
        session,
    })

    const renderPendingReservation = () => {
        return (
            <div className="mt-20">
                <p className="flex flex-col m-5 text-xl font-bold text-center animate-fade-up">
                    {TABLE_PAGE_COMPONENT_TEXT.QR_CODE_SCAN_MESSAGE}
                </p>
                <div className="flex justify-center w-56 p-5 m-auto bg-white border rounded-lg animate-fade-up animate-delay-300 animate-duration-1000 drop-shadow">
                    <DynamicQRCode
                        style={{ height: '100%', maxWidth: '100%', width: '100%' }}
                        value={
                            reservation.id !== ''
                                ? JSON.stringify({
                                      id: reservation.id,
                                      status: reservation.status,
                                      date_of_reservation: reservation.date_of_reservation,
                                  })
                                : 'Welcome'
                        }
                        viewBox={`0 0 256 256`}
                    />
                </div>
            </div>
        )
    }

    const renderSeatedReservation = () => {
        if (tableData.id === '' || reservation.is_active === false) {
            return (
                <DynamicMenuComponent
                    openMenuPopup={true}
                    pageMode={false}
                    modalClassName="h-screen w-screen scroll-py-10 !ease-in-out !duration-300 justify-items-center z-[999999]"
                    containerClassName="!h-screen max-w-lg p-0 m-0 mt-2 pb-10 w-screen flex flex-col overflow-scroll no-scrollbar bg-gray-100 dark:bg-base-100 2xl:mx-auto"
                    setOpenMenuPopup={() => {}}
                    selectedMenuItems={[]}
                    setSelectedMenuItems={() => {}}
                    setCreateOrderPopup={() => {}}
                    setMenuAddonsPopup={setMenuAddonsPopup}
                    setSelectedOrderSingleItem={setSelectedOrderSingleItem}
                    setSelectedItemMode={() => {}}
                    orderMode={ENUM_MODES.VIEW}
                    setOpenCancelOrderPopup={() => {}}
                />
            )
        } else if (tableData.id !== '' && session !== null) {
            return <DynamicTablePageComponent />
        }
        return null
    }

    const renderCompletedReservation = () => {
        return (
            <p className="m-5 text-xl font-bold text-center animate-fade">
                {TABLE_PAGE_COMPONENT_TEXT.COMPLETED_RESERVATION_MESSAGE}
                <FaceSmileIcon className="w-12 h-12 m-auto mt-5" />
            </p>
        )
    }

    const renderReservationNotFound = () => {
        return (
            <p className="m-5 text-xl font-bold text-center animate-fade drop-shadow">
                {TABLE_PAGE_COMPONENT_TEXT.RESERVATION_NOT_FOUND_MESSAGE}
                <FaceFrownIcon className="w-12 h-12 m-auto mt-5" />
            </p>
        )
    }

    const checkIfStaticReservationIsActive =
        reservation.is_static === true &&
        reservation.is_active === false &&
        loadingQueryData === false

    const renderContent = () => {
        if (loadingQueryData && openPinCodePopup === false) {
            return <LoadingSpinner />
        }

        if (checkIfStaticReservationIsActive) {
            return (
                <DynamicMenuComponent
                    openMenuPopup={true}
                    pageMode={false}
                    modalClassName="h-screen w-screen scroll-py-10 !ease-in-out !duration-300 justify-items-center z-[999999]"
                    containerClassName="!h-screen max-w-lg p-0 m-0 mt-2 pb-10 w-screen flex flex-col overflow-scroll no-scrollbar bg-gray-100 dark:bg-base-100 2xl:mx-auto"
                    setOpenMenuPopup={() => {}}
                    selectedMenuItems={[]}
                    setSelectedMenuItems={() => {}}
                    setCreateOrderPopup={() => {}}
                    setMenuAddonsPopup={setMenuAddonsPopup}
                    setSelectedOrderSingleItem={setSelectedOrderSingleItem}
                    setSelectedItemMode={() => {}}
                    orderMode={ENUM_MODES.VIEW}
                    setOpenCancelOrderPopup={() => {}}
                />
            )
        }

        if (
            session &&
            reservation.id !== '' &&
            reservation.is_static === true &&
            loadingQueryData === false
        ) {
            return <DynamicTablePageComponent />
        }

        if (
            session &&
            reservation.id !== '' &&
            (reservation.status === STATUS_CONSTANTS.PENDING ||
                reservation.status === STATUS_CONSTANTS.CONFIRMED) &&
            loadingQueryData === false
        ) {
            return renderPendingReservation()
        } else if (
            session &&
            reservation.status === STATUS_CONSTANTS.SEATED &&
            loadingQueryData === false
        ) {
            return renderSeatedReservation()
        } else if (
            session &&
            reservation.status === STATUS_CONSTANTS.COMPLETED &&
            isWithinRange &&
            loadingQueryData === false
        ) {
            return renderCompletedReservation()
        } else if (
            reservationNotFound ||
            (isWithinRange === false &&
                session &&
                loadingQueryData === false &&
                reservation.is_active)
        ) {
            return renderReservationNotFound()
        }

        return null
    }

    const renderPinCodeComponent = () => {
        if (
            openPinCodePopup &&
            reservation.id !== '' &&
            initializePageLoad === false &&
            checkIfStaticReservationIsActive === false
        ) {
            return (
                <DynamicPinCodeComponent
                    validPinCode={validPinCode}
                    setValue={handleVerifyReservationPinCode}
                    loading={loading}
                />
            )
        }

        return null
    }

    return (
        <CustomerLayout
            isAuthorized={true}
            initializePageLoad={initializePageLoad}
            showNotificationsBell={reservation.is_active}
        >
            <div className="flex justify-center h-screen max-w-lg mx-auto overflow-hidden max-lg:w-full xl:w-1/3 xl:mx-auto">
                {renderContent()}
            </div>
            <ModalPortal>
                {renderPinCodeComponent()}

                {menuAddonsPopup ? (
                    <PopupComponent
                        open={menuAddonsPopup}
                        setOpen={handleCloseMenuItemsPopup}
                        buttonClassName="absolute z-50 cursor-default btn btn-sm btn-circle top-5 right-5 shadow-xl"
                        className="relative flex flex-col justify-center z-40 min-h-[120px] w-full  max-w-lg h-full overflow-hidden bg-gray-100 dark:bg-base-100 animate-fade-up animate-duration-300 animate-once animate-ease-out"
                    >
                        <DynamicMenuItemAddOnSelection
                            menuItem={selectedOrderSingleItem as CategoryItemT}
                            mode={reservation.is_active ? selectedItemMode : ENUM_MODES.VIEW}
                            setSelectedOrderSingleItem={setSelectedOrderSingleItem}
                            setSelectedMenuItems={setSelectedMenuItems}
                            setMenuAddonsPopup={setMenuAddonsPopup}
                        />
                    </PopupComponent>
                ) : null}
            </ModalPortal>
        </CustomerLayout>
    )
}

export default TablePage
